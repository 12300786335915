
import Vue from "vue";
import axios from "axios";
import jsZip from "jszip";
import fileSaver from "file-saver";
import apiUrl from "@/utils/apiUrl";
import * as luxon from "luxon";

type File = {
  name: string;
  extension: string;
  downloadLink: string;
  highlighted: boolean;
  language:
    | "unspecified"
    | "dk"
    | "se"
    | "de"
    | "enu"
    | "es"
    | "fr"
    | "nl"
    | "pl";
  docType: "other" | "datasheet" | "manual";
};

type Section = {
  type: "item";
  id: string;
  files: File[];
};

export default Vue.extend({
  name: "download",
  data: () => ({
    sections: [] as Section[],
    onlyShowHighlighted: false,
    langFilter: [] as string[],
    docTypeFilter: [] as string[],
    loading: false,
  }),
  computed: {
    anyHighlighted(): boolean {
      return this.sections.some((i) => i.files.some((j) => j.highlighted));
    },
  },
  watch: {
    langFilter() {
      this.sections.forEach((section) => {
        section.files.forEach((file) => {
          this.checkHighlight(file);
        });
      });
    },
    docTypeFilter() {
      this.sections.forEach((section) => {
        section.files.forEach((file) => {
          this.checkHighlight(file);
        });
      });
    },
  },
  async mounted() {
    if (this.$router.currentRoute.params.type == "item") {
      const section: Section = {
        type: "item",
        id: this.$router.currentRoute.params.id,
        files: [],
      };
      const res = await axios.get(
        `${apiUrl}/api/item/${this.$router.currentRoute.params.id}/getDocuments`
      );
      res.data.relatedDocs.forEach(
        (x: {
          name: any;
          fileType: any;
          downloadLink: any;
          language:
            | "unspecified"
            | "dk"
            | "se"
            | "de"
            | "enu"
            | "es"
            | "fr"
            | "nl"
            | "pl";
          docType: "other" | "datasheet" | "manual";
        }) =>
          section.files.push({
            name: x.name,
            extension: x.fileType,
            downloadLink: x.downloadLink,
            language: x.language,
            docType: x.docType,
            highlighted: false,
          })
      );
      this.sections.push(section);
    }

    if (this.$router.currentRoute.params.type == "bundle") {
      const bundleRes = await axios.get(
        `${apiUrl}/api/bundle/${this.$router.currentRoute.params.id}`
      );
      for await (const itemId of bundleRes.data.items) {
        const res = await axios.get(
          `${apiUrl}/api/item/${itemId}/getDocuments`
        );
        const section: Section = {
          type: "item",
          id: itemId,
          files: [],
        };
        res.data.relatedDocs.forEach(
          (x: {
            name: any;
            fileType: any;
            downloadLink: any;
            language:
              | "unspecified"
              | "dk"
              | "se"
              | "de"
              | "enu"
              | "es"
              | "fr"
              | "nl"
              | "pl";
            docType: "other" | "datasheet" | "manual";
          }) =>
            section.files.push({
              name: x.name,
              extension: x.fileType,
              downloadLink: x.downloadLink,
              language: x.language,
              docType: x.docType,
              highlighted: false,
            })
        );
        this.sections.push(section);
      }
    }
  },
  methods: {
    async downloadAll() {
      this.loading = true;
      try {
        const files = this.sections
          .map((i) => i.files)
          .flat()
          .sort((a, b) => a.downloadLink.localeCompare(b.downloadLink))
          .filter(
            (file, i, arr) =>
              i == arr.length - 1 ||
              file.downloadLink != arr[i + 1].downloadLink
          );

        const zipper = jsZip();
        for await (const file of files) {
          const blobRes = await axios.get(files[0].downloadLink, {
            responseType: "blob",
          });
          zipper.file(`${file.name}.${file.extension}`, blobRes.data);
        }
        const zip = await zipper.generateAsync({ type: "blob" });
        const fileName = `Geovent-bundle-${luxon.DateTime.now().toISO()}.zip`;
        fileSaver.saveAs(zip, fileName);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async downloadHighlighted() {
      this.loading = true;
      try {
        const files = this.sections
          .map((i) => i.files.filter((file) => file.highlighted))
          .flat()
          .sort((a, b) => a.downloadLink.localeCompare(b.downloadLink))
          .filter(
            (file, i, arr) =>
              i == arr.length - 1 ||
              file.downloadLink != arr[i + 1].downloadLink
          );

        const zipper = jsZip();
        for await (const file of files) {
          const blobRes = await axios.get(files[0].downloadLink, {
            responseType: "blob",
          });
          zipper.file(`${file.name}.${file.extension}`, blobRes.data);
        }
        const zip = await zipper.generateAsync({ type: "blob" });
        const fileName = `Geovent-bundle-${luxon.DateTime.now().toISO()}.zip`;
        fileSaver.saveAs(zip, fileName);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    checkHighlight(file: File) {
      if (
        (this.docTypeFilter.some(
          (type) => type.toLowerCase() == file.docType
        ) ||
          this.docTypeFilter.length == 0) &&
        (this.langFilter.some((lang) => lang.toLowerCase() == file.language) ||
          this.langFilter.length == 0)
      )
        file.highlighted = true;
      else file.highlighted = false;
    },
  },
});
